const ENV = {
  MAIN_URL: process.env.MAIN_URL,
  MAILTO_URL: process.env.MAILTO_URL,
  NOCODB_DATA_HREF: process.env.NOCODB_DATA_HREF,
  NOCODB_SUBMIT_FORM_HREF: process.env.NOCODB_SUBMIT_FORM_HREF,
  UMAMI_TRACKER_URL: process.env.UMAMI_TRACKER_URL,
  UMAMI_SITE_ID: process.env.UMAMI_SITE_ID,
};

document.addEventListener('DOMContentLoaded', ev => {
  // Replace variables that should be environment variables
  document
    .querySelectorAll('a[data-href-replace-env]')
    .forEach(elem => {
      const key = elem.dataset.hrefReplaceEnv;
      if (key in ENV) { elem.href = ENV[key]; }
    });

  const umamiTrackerURL = ENV['UMAMI_TRACKER_URL'];
  const umamiSiteID = ENV['UMAMI_SITE_ID'];
  if (!umamiTrackerURL || !umamiSiteID) { return; }

  // Add Umami script tag to the page
  const scriptElem = document.createElement("script");
  scriptElem.src = umamiTrackerURL;
  scriptElem.dataset['websiteId'] = umamiSiteID;
  document.body.appendChild(scriptElem);
});
